import React from 'react'
import { graphql, withPrefix } from 'gatsby'
import Helmet from 'react-helmet'
import SEO from '../components/SEO'
import Layout from '../layouts/index'
import Call from '../components/Call'

const Home = (props) => {

	const json = props.data.allFeaturesJson.edges

	return (
		<Layout bodyClass="page-home">
			<SEO title="Home" />
			<Helmet>
				<meta
					name="description"
					content="Wiser | We are a top notch medical billing service"
				/>
			</Helmet>
			<div className="intro pb-4">
				<div className="container">
					<h1>Wiser</h1>
					<p>
						We will take care of all your medical billing needs
					</p>
				</div>
			</div>

			<div className="container pt-2">
				<Call button />
			</div>

			{/* <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-3">Our Services</h2>
          </div>
          {markdown.map(edge => (
            <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
										<Link to={edge.node.frontmatter.path}>{edge.node.frontmatter.title}</Link>
                  </h2>
                  <p>{edge.node.excerpt}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/services">
              View All Services
            </Link>
          </div>
        </div>
			</div> */}

			<div className="container pt-8 pt-md-10">
				<div className="row justify-content-start">
					<div className="col-12">
						<h2 className="title-3 text-dark mb-3">Our Services</h2>
					</div>

					<div className="col-12 col-md-4 mb-1">
						<div className="card service service-teaser">
							<div className="card-content">
								<p>
									Timely medical billing & claims submission for Medical insurance,
									Workers Compensation and Auto insurance billing
									Includes Primary, Secondary, & Tertiary insurances
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4 mb-1">
						<div className="card service service-teaser">
							<div className="card-content">
								<p>
									Payment distribution for all insurance and patient payments.
									Follow up on all denied or rejected claims.  File appeals when needed.
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4 mb-1">
						<div className="card service service-teaser">
							<div className="card-content">
								<p>
									Monthly Patient statements: work with office to establish patient collections
									Handle all billing-related patient communications
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4 mb-1">
						<div className="card service service-teaser">
							<div className="card-content">
								<p>
									Aggressive aging reconciliation .
									Practice Management Reports-monthly and when requested
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4 mb-1">
						<div className="card service service-teaser">
							<div className="card-content">
								<p>
									Devise a system with the office for handling insurance verification
									obtaining authorizations/ pre-certifications/referrals
									collecting copays & coinsurance (and deductibles if desired)
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4 mb-1">
						<div className="card service service-teaser">
							<div className="card-content">
								<p>
									We can work with your current billing software or our billing program
									All services offered together or individually
								</p>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div className="container pt-5 pb-5 pt-md-7 pb-md-7">
				<div className="row justify-content-center">
					{json.map(edge => (
						<div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
							<div className="feature">
								{edge.node.image && (
									<div className="feature-image">
										<img src={withPrefix(edge.node.image)} alt={`${edge.node.title} illustration`} />
									</div>
								)}
								<h2 className="feature-title">{edge.node.title}</h2>
								<div className="feature-content">{edge.node.description}</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
  }
`

export default Home
